import { NEW_USER_RATING, THRESHOLD_USER_RATING } from '@config/account';
import { QUERY_PARAM } from '@config/routing';
import { isPhoto } from '@helpers/media-files';
import { IRoute, RouteDescriptor, RouteOptions, RouteParams } from '@routing/route';
import { ROUTES } from '@routing/routes';
import { AVATAR_ID, LEVEL, MediaFiles, MemberId, MemberRating, ONLINE_STATUS, PhotoId, PROF_SPECIALITY, ROLE_TYPE, SEX, USER_ACHIEVEMENT } from '@types';
import { findById, first, isNullable, rejectNullable } from './data';
import { joinWith } from './format';
export const isSpecialist = (role: undefined | ROLE_TYPE): boolean => {
  return role === ROLE_TYPE.SPECIALIST;
};
export const isClient = (role: undefined | ROLE_TYPE): boolean => {
  return role === ROLE_TYPE.CLIENT;
};
export const isPremium = (level: undefined | LEVEL): boolean => {
  return level === LEVEL.PREMIUM;
};
export const isOnline = (onlineStatus: undefined | ONLINE_STATUS): boolean => {
  return onlineStatus === ONLINE_STATUS.ONLINE;
};
export const ensureRatingIsNew = (rating: undefined | MemberRating): boolean => {
  return !rating || rating < NEW_USER_RATING;
};
export const ensureRatingIsLimited = (rating: undefined | MemberRating): boolean => {
  return !rating || rating < THRESHOLD_USER_RATING;
};
export const calculateNameAge = (name: undefined | string, age: undefined | number): undefined | string => {
  return age //
  ? joinWith([name, age], ', ') : name;
};
export const calculateAvatarUrl = (avatarId: AVATAR_ID): string => {
  return `/images/avatars/${avatarId}.svg`;
};
export const calculateStubAvatarId = (sex: undefined | SEX): AVATAR_ID => {
  return sex === SEX.FEMALE ? AVATAR_ID.AVATAR_FEMALE_STUB : AVATAR_ID.AVATAR_MALE_STUB;
};
export const calculateDefaultAvatarId = (sex: undefined | SEX): AVATAR_ID => {
  return sex === SEX.FEMALE ? AVATAR_ID.AVATAR_FEMALE_DEFAULT : AVATAR_ID.AVATAR_MALE_DEFAULT;
};
export const photoOrAvatarUrl = (photoUrl: undefined | null | string, avatarId: undefined | null | AVATAR_ID, sex: undefined | SEX): string => {
  if (photoUrl) {
    return photoUrl;
  }
  return calculateAvatarUrl(avatarId ?? calculateStubAvatarId(sex));
};
export const isStubAvatar = (avatarId: AVATAR_ID): boolean => {
  return [AVATAR_ID.AVATAR_FEMALE_STUB,
  //
  AVATAR_ID.AVATAR_MALE_STUB].includes(avatarId);
};
export const toUserAchievements = (isCertified: undefined | null | boolean, profSpecialiy: undefined | null | PROF_SPECIALITY): USER_ACHIEVEMENT[] => {
  const achievements = {
    [PROF_SPECIALITY.CONSULTANT]: USER_ACHIEVEMENT.CONSULTANT,
    [PROF_SPECIALITY.SEXOLOGIST]: USER_ACHIEVEMENT.SEXOLOGIST,
    [PROF_SPECIALITY.COACH]: USER_ACHIEVEMENT.COACH,
    [PROF_SPECIALITY.FREE_CONSULTANT]: USER_ACHIEVEMENT.FREE_CONSULTANT
  };
  return rejectNullable([isCertified //
  ? USER_ACHIEVEMENT.DIPLOMA : undefined, !isNullable(profSpecialiy) //
  ? achievements[profSpecialiy] : undefined]);
};
export const fromUserAchievements = (achievements: undefined | USER_ACHIEVEMENT[]): {
  isCertified: undefined | boolean;
  profSpeciality: undefined | PROF_SPECIALITY;
} => {
  const profSpecialitiesMap = {
    [USER_ACHIEVEMENT.DIPLOMA]: undefined,
    [USER_ACHIEVEMENT.COACH]: PROF_SPECIALITY.COACH,
    [USER_ACHIEVEMENT.CONSULTANT]: PROF_SPECIALITY.CONSULTANT,
    [USER_ACHIEVEMENT.SEXOLOGIST]: PROF_SPECIALITY.SEXOLOGIST,
    [USER_ACHIEVEMENT.FREE_CONSULTANT]: undefined
  };
  const isCertified = (achievements ?? []).includes(USER_ACHIEVEMENT.DIPLOMA);
  const profSpecialities = rejectNullable((achievements ?? []).map(achievement => profSpecialitiesMap[achievement]));
  const profSpeciality = first(profSpecialities);
  return {
    isCertified,
    profSpeciality
  };
};
export const defineRoleTypeByRoute = <P extends RouteParams,>(route: undefined | IRoute<P>): ROLE_TYPE => {
  switch (route) {
    case ROUTES.psychologist.index:
      return ROLE_TYPE.SPECIALIST;
    default:
      return ROLE_TYPE.CLIENT;
  }
};
export const defineNavigationByRoleType = (roleType: ROLE_TYPE, id: MemberId, options?: RouteOptions): RouteDescriptor<{
  clientId: string;
}> | RouteDescriptor<{
  specialistId: string;
}> => {
  return {
    [ROLE_TYPE.CLIENT]: ROUTES.clients.id.getDescriptor({
      [QUERY_PARAM.PATHNAME_CLIENT_ID]: id
    }, options),
    [ROLE_TYPE.SPECIALIST]: ROUTES.psychologists.id.getDescriptor({
      [QUERY_PARAM.PATHNAME_SPECIALIST_ID]: id
    }, options)
  }[roleType];
};
export const findDefaultPhoto = (media: MediaFiles, defaultPhotoId: PhotoId | undefined): string | undefined => {
  if (!media || !defaultPhotoId) {
    return undefined;
  }
  const mediaFile = findById(media.filter(isPhoto), defaultPhotoId);
  return mediaFile?.url;
};