const plugin = require('tailwindcss/plugin');

const { screens } = require('./tailwind/screens');
const { colors } = require('./tailwind/colors');
const { backgroundImage } = require('./tailwind/bg-images');

const { backgroundImageUtilites } = require('./tailwind/utilites/bg-utilites');
const { flexUtilites } = require('./tailwind/utilites/flex-utilites');
const { flipUtilites } = require('./tailwind/utilites/flip-utilites');
const { filterUtilites } = require('./tailwind/utilites/filter-utilites');
const {
  scrollbarHideUtilites,
} = require('./tailwind/utilites/scrollbar-utilites');
const {
  groupHoverVariant,
} = require('./tailwind/variants/group-hover-variant');
const { oddOfTypeVariant } = require('./tailwind/variants/odd-of-type-variant');
const { hoverVariant } = require('./tailwind/variants/hover-variant');
const { activeVariant } = require('./tailwind/variants/active-variant');
const {
  notLastChildVariant,
} = require('./tailwind/variants/not-last-child-variant');

module.exports = {
  theme: {
    screens,
    colors,
    stroke: colors,
    fill: colors,
    inset: {
      '25p': '25%',
      '40p': '40%',
      '1em': '1em',
      half: '50%',
      full: '100%',
      '-9999': '-9999px',
      ...withStep(-30, 45, 0.0625, 'rem'),
    },
    width: {
      unset: 'unset',
      auto: 'auto',
      app: 'var(--app-width)',
      'fit-content': 'fit-content',
      '20p': '20%',
      '33p': '33.33%',
      '40p': '40%',
      half: '50%',
      '55p': '55%',
      '60p': '60%',
      '65p': '65%',
      full: '100%',
      '1em': '1em',
      ...withStep(0, 100, 0.125, 'rem'),
    },
    minWidth: {
      unset: 'unset',
      auto: 'auto',
      inherit: 'inherit',
      'fit-content': 'fit-content',
      full: '100%',
      ...withStep(0, 100, 0.125, 'rem'),
    },
    maxWidth: {
      unset: 'unset',
      app: 'var(--app-width)',
      'max-content': 'max-content',
      '80p': '80%',
      full: '100%',
      ...withStep(0, 100, 0.125, 'rem'),
      110: '110rem',
      120: '120rem',
    },
    height: {
      unset: 'unset',
      auto: 'auto',
      app: 'var(--app-height)',
      full: '100%',
      screen: '100vh',
      '1em': '1em',
      '40p': '40%',
      '92p': '92%',
      ...withStep(0, 100, 0.125, 'rem'),
    },
    minHeight: {
      unset: 'unset',
      app: 'var(--app-height)',
      full: '100%',
      ...withStep(0, 100, 0.125, 'rem'),
    },
    maxHeight: {
      unset: 'unset',
      app: 'var(--app-height)',
      full: '100%',
      ...withStep(0, 100, 0.125, 'rem'),
    },
    padding: {
      '10p': '10%',
      ...withStep(0, 20, 0.0625, 'rem'),
    },
    margin: {
      auto: 'auto',
      '-15p': '-15%',
      ...withStep(-20, 30, 0.125, 'rem'),
    },
    space: {
      ...withStep(0, 20, 0.125, 'rem'),
    },
    gap: {
      ...withStep(0, 20, 0.125, 'rem'),
    },
    borderWidth: {
      0: '0px',
      1: '1px',
      2: '2px',
      4: '4px',
      '0x5': '0.5rem',
      '0x9375': '0.9375rem',
      '1x25': '1.25rem',
    },
    borderRadius: {
      half: '50%',
      ...withStep(0, 20, 0.125, 'rem'),
    },
    fontSize: {
      ...withStep(0.125, 12, 0.0625, 'rem'),
    },
    lineHeight: {
      100: '1',
      130: '1.3',
      140: '1.4',
      150: '1.5',
      160: '1.6',
      ...withStep(0.25, 8, 0.0625, 'rem'),
    },
    letterSpacing: {
      normal: 'normal',
      ...withStep(-0.1, 0.1, 0.0125, 'em'),
    },
    fontWeight: {
      400: 400,
      500: 500,
      600: 600,
      700: 700,
    },
    placeholderColor: colors,
    boxShadow: {
      none: 'none',
      'inner-bottom-0x1875-blue-90-39-100': `inset 0px -0.25rem 0px -1px ${colors['blue-90-39-100']}`,
      'inner-bottom-0x25-blue-90-39-100': `inset 0px -0.25rem 0px 0px ${colors['blue-90-39-100']}`,
      'inner-bottom-0x5-blue-90-39-100': `inset 0px -0.5rem 0px 0px ${colors['blue-90-39-100']}`,
      'inner-left-0x25-blue-90-39-100': `inset 0.25rem 0px 0px 0px ${colors['blue-90-39-100']}`,
      'outer-3px-blue-88-61-100': `0px 0px 3px ${colors['blue-88-61-100']}`,
      'outer-3px-red-82-59-100': `0px 0px 3px ${colors['red-82-59-100']}`,
      'outer-10px-black-0-0-20': `0px 0px 10px ${colors['black-0-0-20']}`,
      'inner-15px-red-42-55-20': `0px 4px 15px ${colors['red-42-55-20']}`,
      'inner-15px-blue-42-55-20': `0px 4px 15px ${colors['blue-42-55-20']}`,
    },
    opacity: {
      ...withStep(0, 1, 0.1),
    },
    scale: {
      unset: 'unset',
      '75p': '0.75',
      '95p': '0.95',
      '104p': '1.04',
      '140p': '1.4',
      '150p': '1.5',
    },
    zIndex: {
      unset: 'unset',
      '-1': -1,
      0: 0,
      100: 100, // misc
      110: 110,
      500: 500, // fixed
      900: 900, // notifications
      1000: 1000, // popups
    },
    translate: {
      0: 0,
      '-full': '-100%',
      '-half': '-50%',
      half: '50%',
      full: '100%',
      ...withStep(-10, 10, 0.125, 'rem'),
    },
    extend: {
      backgroundImage,
      backgroundSize: {
        full: '100%',
        '100p-100p': '100% 100%',
        60: '60rem',
        ...withStep(0, 8, 0.125, 'rem'),
      },
    },
  },
  content: [
    './src/components/**/*.{js,ts,jsx,tsx}',
    './src/pages/**/*.{js,ts,jsx,tsx}',
  ],
  plugins: [
    plugin(flexUtilites),
    plugin(flipUtilites),
    plugin(filterUtilites),
    plugin(backgroundImageUtilites),
    plugin(scrollbarHideUtilites),
    plugin(groupHoverVariant),
    plugin(oddOfTypeVariant),
    plugin(hoverVariant),
    plugin(activeVariant),
    plugin(notLastChildVariant),
  ],
};

function withStep(start, end, step, units = '') {
  let sizeUtilites = {};
  /**
   * @todo Replace the solution with toFixed below that prevents float numbers
   * issue with more common solution
   */
  const chunks = String(step).split('.');
  const decimals = chunks[1] ? chunks[1].length : 0;

  for (let i = start; i <= end; ) {
    const name = String(i).replace('.', 'x');
    sizeUtilites = {
      ...sizeUtilites,
      [name]: `${i}${units}`,
    };

    i = Number(parseFloat(i + step).toFixed(decimals));
  }

  return sizeUtilites;
}
