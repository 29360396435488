import { isBuyCoinsTryData } from '@helpers/logging/analytics/buy-coins-try-event';
import { isBuyPremiumTryData } from '@helpers/logging/analytics/buy-premium-try-event';
import { currencyToCurrencyCode, priceToDto } from '@helpers/payments';
import { AnalyticsSendEventRequestData, AnalyticsSendEventRequestDto } from '@services/dto/analytics/analytics-send-event';
export const analyticsSendEventRequestToDto = (data: AnalyticsSendEventRequestData): AnalyticsSendEventRequestDto => {
  if (isBuyCoinsTryData(data)) {
    return {
      aggregatorId: data.data.aggregatorId,
      paymentType: data.data.paymentType,
      moneyAmount: priceToDto(data.data.moneyAmount),
      coinsAmount: data.data.coinsAmount,
      currencyCode: currencyToCurrencyCode(data.data.currency),
      paymentSystem: data.data.paymentSystem
    };
  }
  if (isBuyPremiumTryData(data)) {
    return {
      aggregatorId: data.data.aggregatorId,
      paymentType: data.data.paymentType,
      paymentSystem: data.data.paymentSystem,
      commandTrigger: data.data.commandTrigger,
      currencyCode: currencyToCurrencyCode(data.data.currency),
      moneyAmount: priceToDto(data.data.moneyAmount)
    };
  }
  return data.data;
};