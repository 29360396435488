export type MemberRating = number;
export enum SEX {
  MALE = 1,
  FEMALE = 2,
}
export enum LEVEL {
  BASIC = 1,
  PREMIUM = 2,
}
export enum PROF_SPECIALITY {
  CONSULTANT = 1,
  SEXOLOGIST = 2,
  COACH = 3,
  FREE_CONSULTANT = 5,
}
export enum ONLINE_STATUS {
  OFFLINE = 1,
  ONLINE = 2,
}
export type MemberId = string;
export type MemberIds = MemberId[];
export type MemberProfileState = {
  hasDefaultPhoto: boolean;
  hasPhotos: boolean;
  infoFilled: boolean;
  onModeration: boolean;
  isEverApproved: boolean;
};